// The selectors in this file are not all prefixed with `.js-` because we don't have the ability to add
// classes where we need to in the style system since this isn't a custom component.
const showFooterCssClass = 'jsa-footer__show-links';

const toggleNavClass = (headerEl: HTMLElement): void => {
  headerEl.classList.toggle(showFooterCssClass);
};

const applyHeaderToggle = (footerNavEl: HTMLElement): void => {
  const headerEl: HTMLElement | null = footerNavEl.querySelector(
    '.cmp-linklist__header'
  );
  const navEl: HTMLElement | null = footerNavEl.querySelector(
    '.cmp-linklist__links'
  );

  if (!headerEl || !navEl) {
    return;
  }

  headerEl.addEventListener(
    'click',
    toggleNavClass.bind(undefined, headerEl),
    false
  );
};

const initiateFooterNav = (): void => {
  const footerNavEls: NodeListOf<HTMLElement> = document.querySelectorAll(
    '.cmp-footer__nav .cmp-linklist'
  );

  footerNavEls.forEach(applyHeaderToggle);
};

initiateFooterNav();
